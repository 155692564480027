import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`We are making AI technology accessible to everyone`}</p>
    <p>{`About The Company
We are Kata.ai, an Indonesian conversational Artificial Intelligence company, focused on understanding human conversation so we can empower the way humans collaborate with technology. Kata.ai’s Natural Language Processing (NLP) technology powers multi-purpose chatbots for major corporations in Indonesia across different industries, including FMCG, Telecommunication, Banking & Financial Service, and Retail.`}</p>
    <p>{`We provide powerful conversational AI technology`}</p>
    <p>{`So your brand can build engaging interaction with customers`}</p>
    <p>{`For better sales, superior customer service, or effective marketing campaign.
The History
Established in 2015, we started our journey as YesBoss, offering virtual assistant service through SMS and a mobile app. Along the way, we learned that we wanted a business that is more impactful and scalable. In 2016 YesBoss was no longer active and we had to pivot our business into the B2B segment with the launch of Kata.ai`}</p>
    <p>{`Our goal in Kata.ai is to offer natural language dialogue engine that enables brands and enterprises to build their own chatbots to perform conversational activities ranging from product marketing, commerce, to behavioral data gathering via commonly-used social and chat apps such as LINE, Facebook, Twitter, Telegram, Slack, BBM, and WhatsApp. Helping businesses to understand the behavior of their customers and build a better relationship.`}</p>
    <p>{`Our journey hasn’t always been easy, but we embrace it to learn and get better. Our team is aligned and working hard to make great products and solutions.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      